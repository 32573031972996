<template>
    <td>
        <multiselect
            v-model="opcion"
            :options="pregunta.opciones"
            :multiple="multiple"
            :closeOnSelect="!multiple"
            track-by="id"
            :label="comboLabel"
            :show-labels="false"
            @input="seleccionar"
        ></multiselect>
        <input
            v-if="especifique"
            type="text"
            class="form-control form-control mt-4"
            v-model="especificacion"
            placeholder="Especifique"
            @change="especificar"
        />
    </td>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Decimal from "decimal.js";
import Multiselect from "vue-multiselect";

export default {
    name: "OpcionMultiple",
    props: ["pregunta", "tipo", "lang"],
    data() {
        return {
            ...mapGetters(["getAnswers"]),
            opcion: null,
            especificacion: null,
        };
    },
    mounted() {
        let answers = this.getAnswers();
        let answer = answers.find((ans) => {
            let pregunta_id = new Decimal(ans.pregunta);

            return pregunta_id.eq(this.pregunta.id) && ans.tipo === this.tipo;
        });

        if (answer) {
            if (answer.respuesta) {
                this.opcion = answer.respuesta;
                this.especificacion = answer.observaciones;
            }
        }
    },
    computed: {
        comboLabel() {
            if (this.lang == 2) {
                return "opcion_en";
            }

            return "opcion";
        },
        multiple() {
            if (this.pregunta.tipo_id == 3) {
                return true;
            }

            return false;
        },
        especifique() {
            if (this.pregunta.tipo_id == 1) {
                return false;
            }

            let answers = this.getAnswers();
            let answer = answers.find((ans) => {
                let pregunta_id = new Decimal(ans.pregunta);

                return pregunta_id.eq(this.pregunta.id) && ans.tipo === this.tipo;
            });

            if (!answer) {
                return false;
            }

            let result = answer.respuesta;

            if (!result) {
                return false;
            }

            if (Array.isArray(result)) {
                let filtered = result.filter((res) => {
                    return res.especifique == 1;
                });

                if (filtered.length > 0) {
                    return true;
                }
            } else {
                if (result.especifique == 1) {
                    return true;
                }
            }

            return false;
        },
    },
    methods: {
        ...mapActions(["setAnswer"]),
        cambiarRespuesta() {
            this.setAnswer({
                pregunta: this.pregunta.id,
                tipo: this.tipo,
                respuesta: this.opcion,
                observaciones: this.especificacion,
            });
        },
        seleccionar() {
            this.cambiarRespuesta();
        },
        especificar() {
            this.cambiarRespuesta();
        },
    },
    components: {
        Multiselect,
    },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
