<template>
    <div>
        <h4>Ingrese la información solicitada</h4>
        <div v-if="participant">
            <input
                type="text"
                class="form-control form-control-lg mt-4"
                v-model="participant.name"
                placeholder="Name"
            />
            <input
                type="email"
                class="form-control form-control-lg mt-4"
                v-model="participant.email"
                placeholder="Email"
            />
            <input
                type="text"
                class="form-control form-control-lg mt-4"
                v-model="participant.company"
                placeholder="Company name"
            />
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    mounted() {
        this.participant = this.getParticipant();
    },
    data() {
        return {
            ...mapGetters(["getParticipant"]),
            participant: null,
        };
    },
    methods: {
        ...mapActions(["setParticipant"]),
    },
    watch: {
        participant: {
            deep: true,

            handler() {
                this.setParticipant(this.participant);
            },
        },
    },
};
</script>

<style></style>
