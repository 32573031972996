<template>
    <tr>
        <td v-if="tipo == 'Real'">{{ pregunta.pregunta_real }}</td>
        <td v-else-if="tipo == 'Ideal'">{{ pregunta.pregunta }}</td>
        <td v-else>{{ textoPregunta }}</td>
        <td v-if="pregunta.tipo_id == 1">
            <div class="btn-group" role="group">
                <Opcion v-for="(e, i) in 11" :key="i" :pregunta="pregunta" :tipo="tipo" :opcion="i" />
            </div>
        </td>
        <OpcionMultiple v-else :pregunta="pregunta" :tipo="tipo" :lang="lang" />
    </tr>
</template>

<script>
import Opcion from "../components/Opcion.vue";
import OpcionMultiple from "../components/OpcionMultiple.vue";

export default {
    name: "Pregunta",
    props: ["pregunta", "tipo", "lang"],
    components: {
        Opcion,
        OpcionMultiple,
    },
    computed: {
        textoPregunta() {
            if (this.lang == 1) {
                return this.pregunta.pregunta;
            } else if (this.lang == 2) {
                return this.pregunta.pregunta_en;
            }

            return "No se ha configurado idioma";
        },
    },
};
</script>

<style></style>
