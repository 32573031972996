<template>
    <div class="text-center">
        <h1>!Bienvenido!</h1>
        <p>Ingrese el código de la evaluación</p>
        <div class="col-4 offset-4">
            <input class="form-control form-control-lg text-center" type="text" v-model="pin" />
            <br />
            <button class="btn btn-primary" @click="verEvaluacion">
                Ver evaluación
            </button>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            pin: null,
        };
    },
    methods: {
        verEvaluacion() {
            if (this.pin) {
                window.location.href = process.env.VUE_APP_APP_URL + this.pin;
            }
        },
    },
};
</script>

<style></style>
