<template>
    <div>
        <div class="row text-center" v-if="loading || !evaluacion">
            <div class="fa-3x">
                <i class="fas fa-circle-notch fa-spin"></i>
            </div>
            <h2>Cargando</h2>
        </div>
        <form-wizard
            v-else
            shape="tab"
            color="#0090cf"
            back-button-text="Anterior"
            next-button-text="Siguiente"
            finish-button-text="Finalizar"
            @on-complete="finalizar"
        >
            <h2 slot="title">{{ evaluacion.nombre }}</h2>

            <tab-content title="Intro">
                <p class="text-justify" v-html="evaluacion.introduccion"></p>
            </tab-content>
            <tab-content title="Registrar" :before-change="validarUsuario">
                <Registro />
            </tab-content>
            <template v-if="evaluacion.tipo_evaluacion_id == 1">
                <tab-content v-for="seccion in secciones" :key="seccion + ` - Ideal`" :title="seccion + ` - Ideal`">
                    <Subseccion :seccion="seccion" :preguntas="preguntas" tipo="Ideal" :lang="evaluacion.idioma_id" />
                </tab-content>
                <tab-content v-for="seccion in secciones" :key="seccion + ` - Real`" :title="seccion + ` - Real`">
                    <Subseccion :seccion="seccion" :preguntas="preguntas" tipo="Real" :lang="evaluacion.idioma_id" />
                </tab-content>
            </template>
            <template v-if="evaluacion.tipo_evaluacion_id == 2">
                <tab-content v-for="seccion in secciones" :key="`seccion` + seccion.id" title="Preguntas">
                    <Subseccion
                        :seccion="seccion"
                        :preguntas="preguntas"
                        tipo="Opciones"
                        :lang="evaluacion.idioma_id"
                    />
                </tab-content>
            </template>
        </form-wizard>
    </div>
</template>

<script>
import axios from "axios";
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import Registro from "../components/Registro.vue";
import Subseccion from "../components/Subseccion.vue";
import { mapGetters, mapActions } from "vuex";
import Decimal from "decimal.js";

export default {
    name: "Evaluation",
    props: ["pin"],
    components: {
        FormWizard,
        TabContent,
        Registro,
        Subseccion,
    },
    data() {
        return {
            ...mapGetters(["getAnswers", "getParticipant"]),
            loading: false,
            evaluacion: null,
            secciones: [],
            preguntas: [],
        };
    },
    mounted() {
        this.loading = true;

        this.clearAnswers();
        this.clearParticipant();

        axios
            .get(`/api/servqual/evaluaciones/` + this.$route.params.pin)
            .then((res) => {
                this.evaluacion = res.data.evaluacion;
                this.secciones = res.data.secciones;
                this.preguntas = res.data.preguntas;
                this.loading = false;
            })
            .catch((err) => {
                let message = err.response ? (err.response.data.message ? err.response.data.message : "") : "";

                this.$swal({
                    title: "Se dió un problema cargando la evaluación",
                    text: message,
                    icon: "error",
                }).then(function() {
                    window.location.href = "/";
                });
            });
    },
    methods: {
        ...mapActions(["clearAnswers", "clearParticipant"]),
        validarUsuario() {
            let participante = this.getParticipant();

            if (
                !participante.name ||
                participante.name == "" ||
                !participante.email ||
                participante.email == "" ||
                !participante.company ||
                participante.company == ""
            ) {
                this.$swal({
                    title: "Error",
                    text: "Todos los campos son requeridos",
                    icon: "error",
                });

                return false;
            }

            return true;
        },
        finalizar() {
            let count_respuestas = new Decimal(this.getAnswers().length);
            let count_preguntas = new Decimal(this.evaluacion.preguntas.length);
            let participante = this.getParticipant();
            let pin = this.$route.params.pin;

            // if (this.evaluacion.tipo_evaluacion_id == 1) {
            //     count_respuestas = count_respuestas.times(2);
            // }

            if (!count_respuestas.eq(count_preguntas)) {
                this.$swal({
                    title: "Error",
                    text: "Todas las preguntas son requeridas",
                    icon: "error",
                });

                return false;
            }

            if (
                !participante.name ||
                participante.name == "" ||
                !participante.email ||
                participante.email == "" ||
                !participante.company ||
                participante.company == ""
            ) {
                this.$swal({
                    title: "Error",
                    text: "Todos los campos de registro son requeridos",
                    icon: "error",
                });

                return false;
            }

            axios
                .post("/api/servqual/evaluaciones", {
                    pin: pin,
                    participante: participante,
                    respuestas: this.getAnswers(),
                })
                .then((res) => {
                    console.log(res);

                    // this.clearAnswers();
                    // this.clearParticipant();

                    // this.$swal({
                    //     title: "Thank you",
                    //     text: "Your answers have been saved succesfully",
                    //     icon: "success",
                    // }).then(function() {
                    //     window.location.href = "https://www.dalecarnegie.com/en";
                    // });

                    window.location.href = "/#/" + pin + "/thankyou";
                })
                .catch((err) => {
                    let msg = "";
                    let errors = err.response.data.errors;
                    if (errors) {
                        let keys = Object.keys(errors);
                        console.log(keys);

                        keys.forEach(function(key) {
                            console.log(errors[key]);
                            msg += errors[key] + "\n";
                        });
                    }

                    this.$swal({
                        title: "There was a problem saving",
                        text: msg,
                        icon: "error",
                    });
                });
        },
    },
};
</script>

<style></style>
