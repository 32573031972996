import Vue from 'vue'
import VueRouter from 'vue-router'
import Evaluation from '../views/Evaluation.vue'
import Home from '../views/Home.vue'
import ThankYou from '../views/ThankYou.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Welcome',
        component: Home
    },
    {
        path: '/:pin',
        name: 'Evaluation',
        component: Evaluation
    },
    {
        path: '/:pin/thankyou',
        name: 'ThankYou',
        component: ThankYou
    },
]

const router = new VueRouter({
    base: process.env.BASE_URL,
    routes
})

export default router
