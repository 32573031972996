import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import * as types from './mutation-types'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    answers: [],
    participant: {
        name: null,
        email: null,
        company: null
    }
  },
  getters: {
    getAnswers: state => state.answers,
    getParticipant: state => state.participant,
  },
  mutations: {
    [types.SET_ANSWER] (state, {data}) {
        let answer = state.answers.find((ans) => {
            return ans.pregunta == data.pregunta && ans.tipo == data.tipo
        });

        if (answer) {
            console.log(state.answers.indexOf(answer))
            state.answers.splice(state.answers.indexOf(answer), 1)
        }

        state.answers.push(data)
    },
    [types.CLEAR_ANSWERS] (state) {
        state.answers = []
    },
    [types.SET_PARTICIPANT] (state, { name, email, company }) {
        state.participant = {
            name: name,
            email: email,
            company: company
        }
    },
    [types.CLEAR_PARTICIPANT] (state) {
        state.participant = {
            name: null,
            email: null,
            company: null
        }
    },
  },
  actions: {
    setAnswer({commit}, data) {
        commit(types.SET_ANSWER, {
            data: data
        });
    },
    clearAnswers({commit}) {
        commit(types.CLEAR_ANSWERS)
    },
    setParticipant({commit}, data) {
        commit(types.SET_PARTICIPANT, data);
    },
    clearParticipant({commit}) {
        commit(types.CLEAR_PARTICIPANT)
    },
  },
  plugins: [createPersistedState()]
})
