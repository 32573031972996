<template>
    <div class="text-center big-margin">
        <i class="fas fa-check fa-4x"></i>
        <h1 class="small-margin">Muchas gracias</h1>
        <p>Tus respuestas fueron almacenadas correctamente.</p>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
};
</script>

<style>
.big-margin {
    margin-top: 100px;
}
.small-margin {
    margin-top: 30px;
}
</style>
