import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import './sass/app.scss'
import store from './store'
import VueSweetalert2 from 'vue-sweetalert2';

import 'sweetalert2/dist/sweetalert2.min.css';

require('bootstrap');

Vue.use(VueSweetalert2);
Vue.config.productionTip = false

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.headers.common = {
    'Authorization': 'Bearer ' + process.env.VUE_APP_API_TOKEN,
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Access-Control-Allow-Origin': '*',
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
