<template>
    <button type="button" :class="buttonClass" @click="seleccionar">
        {{ opcion == 0 ? "N/A" : opcion }}
    </button>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Decimal from "decimal.js";

export default {
    name: "Opcion",
    props: ["pregunta", "tipo", "opcion"],
    data() {
        return {
            ...mapGetters(["getAnswers"]),
        };
    },
    computed: {
        buttonClass() {
            let answers = this.getAnswers();
            let answer = answers.find((ans) => {
                let pregunta_id = new Decimal(ans.pregunta);

                return pregunta_id.eq(this.pregunta.id) && ans.tipo === this.tipo;
            });

            if (answer && this.pregunta.tipo_id == 1) {
                let respuesta = new Decimal(answer.respuesta);

                if (respuesta.eq(this.opcion)) {
                    return "btn btn-sm btn-outline-danger active";
                }
            }

            return "btn btn-sm btn-outline-danger";
        },
    },
    methods: {
        ...mapActions(["setAnswer"]),
        seleccionar() {
            this.setAnswer({
                pregunta: this.pregunta.id,
                respuesta: this.opcion,
                tipo: this.tipo,
                observaciones: null,
            });
        },
    },
};
</script>

<style></style>
