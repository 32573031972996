<template>
    <div>
        <div v-for="(preguntas, subseccion) in subsecciones" :key="subseccion">
            <h4>{{ subseccion }}</h4>
            <table class="table table-sm table-bordered text-left">
                <thead>
                    <tr>
                        <th width="70%">Pregunta</th>
                        <th width="30%"></th>
                    </tr>
                </thead>
                <tbody>
                    <Pregunta
                        v-for="pregunta in preguntas"
                        :key="pregunta.id"
                        :pregunta="pregunta"
                        :tipo="tipo"
                        :lang="lang"
                    />
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import Pregunta from "../components/Pregunta.vue";

export default {
    name: "Subseccion",
    props: ["seccion", "preguntas", "tipo", "lang"],
    components: {
        Pregunta,
    },
    data() {
        return {
            subsecciones: this.preguntas[this.seccion],
        };
    },
};
</script>

<style></style>
